import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async getAllShippingLabels(filter, pageable) {
    const result = await axios.post(endpoints.shipmentLabels.Controller.getAllShippingLabels(pageable), filter)
    return result.data.body
  },

  async getTrackingsWithSameReference(shipmentOperationId) {
    const result = await axios.get(endpoints.shipmentLabels.Controller.getTrackingsWithSameReference(shipmentOperationId))
    return result.data.body
  },

  async voidShippingLabel(shipmentOperationId) {
    const result = await axios.delete(endpoints.shipmentLabels.Controller.voidShippingLabel(shipmentOperationId))
    return result.data.body
  },

  async purchaseShippingLabel(data) {
    const result = await axios.post(endpoints.shipmentLabels.Controller.purchaseShippingLabel(), data)
    return result.data.body
  },

  async getShippingRates(data) {
    const result = await axios.post(endpoints.shipmentLabels.Controller.getShippingRates(), data)
    return result.data.body
  },

  async getShippingLabelByReference(referenceKey, referenceId) {
    const response = await axios.get(endpoints.shipmentLabels.Controller.getShippingLabelByReference(referenceKey, referenceId))
    return { data: response.data.body }
  },
  async getFbaBoxDimensions(batchId) {
    const response = await axios.get(endpoints.shipmentLabels.Controller.getFbaBoxDimensions(batchId))
    return response.data.body
  },

  // INTERNATIONAL SHIPMENTS - CUSTOMS DECLARATION
  async getCustomsDeclaration(referenceKey, referenceId) {
    const result = await axios.get(endpoints.shipmentLabels.Controller.getCustomsDeclaration(referenceKey, referenceId))
    return result.data.body
  },
  async updateCustomsDeclaration(data) {
    const result = await axios.put(endpoints.shipmentLabels.Controller.updateCustomsDeclaration(), data)
    return result
  },
  async getProductDefaults(inventoryId) {
    const result = await axios.get(endpoints.shipmentLabels.Controller.getProductDefaults(inventoryId))
    return result.data.body
  },
  async deleteCustomsDeclaration(id) {
    const result = await axios.get(endpoints.shipmentLabels.Controller.deleteCustomsDeclaration(id))
    return result.data.body
  },
}
